import React, { useState } from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {  Autoplay } from 'swiper/core';
// import Swiper styles
import 'swiper/swiper-bundle.css'
SwiperCore.use([Autoplay]);

export default function AboutList({ pageContext: { node },location }) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")

    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="container mx-auto pt-[200px] pb-[100px] flex items-start justify-between xl:flex-col sm:pt-24 sm:pb-20">
                <h1 className="text-[40px] leading-snug xl:mb-8">{node.title}</h1>
                <article className="w-[944px] xl:w-full about">
                    <h2>{node.subTitle}</h2>
                    {
                        node.url == '/core-competence'?
                        <div className="grid grid-cols-2 gap-y-[70px] gap-x-[30px] xl:grid-cols-1">
                            <div>
                                <figure className="w-full overflow-hidden"><GatsbyImage className="w-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(node.carousel.image[0].image.localFile)} alt="product"/></figure>
                                <h2 className="text-3xl leading-snug py-5">{node.carousel.image[0].title}</h2>
                                <p>{node.carousel.image[1].info}</p>
                            </div>
                            <div>
                                <figure className="w-full overflow-hidden"><GatsbyImage className="w-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(node.carousel.image[1].image.localFile)} alt="product"/></figure>
                                <h2 className="text-3xl leading-snug py-5">{node.carousel.image[1].title}</h2>
                                <p>{node.carousel.image[1].info}</p>
                            </div>
                            <div className="col-span-2 xl:col-span-1">
                                <h2 className="text-3xl leading-snug pb-5">{node.carousel.image[2].title}</h2>
                                <figure className="w-full overflow-hidden"><GatsbyImage className="w-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(node.carousel.image[2].image.localFile)} alt="product"/></figure>
                                <p className="pt-5">{node.carousel.image[2].info}</p>
                            </div>
                        </div>:
                        <Swiper 
                            autoplay={{
                                disableOnInteraction: false
                            }}
                            loop={true}
                            className="my-10"
                            >
                            {
                                node.carousel.image.map((n,i,a)=>{
                                    return <SwiperSlide key={i}>
                                                <GatsbyImage className="w-full" image={getImage(n.image.localFile)} alt="product"/>
                                                <p className="pt-2.5">0{i+1}/0{a.length} {n.title}</p>
                                        </SwiperSlide>
                                })
                            }
                        </Swiper>
                    }
                    
                    <div dangerouslySetInnerHTML={{__html: node.content}} />
                </article>
            </div>
        </Layout>
    )
}

